import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const LabelContainer = styled(Box)(({ theme: { breakpoints, spacing } }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',

  '& > .MuiBox-root': {
    display: 'inline',
  },

  '& .count-label': {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    bottom: 0,
    left: 0,
    gap: spacing(2),
  },

  [breakpoints.up('md')]: {
    '& .count-label': {
      position: 'initial',
      bottom: 'initial',
      left: 'initial',
    },
  },
}));

interface SelectionLabelProps {
  selectedCount: number | React.ReactNode;
  totalCount: number;
  label: string;
}

const SelectionLabel = ({
  label,
  selectedCount,
  totalCount,
}: SelectionLabelProps): JSX.Element => (
  <LabelContainer>
    <Box>{label}</Box>
    <Box className="count-label">
      {selectedCount} out of {totalCount}
    </Box>
  </LabelContainer>
);

export default SelectionLabel;

import { FetchResult } from '@apollo/client';
import {
  EDifficultyType,
  EProductType,
  EStudyAction,
  ETopicType,
  IConcept,
  ICondition,
  IPresentation,
  ITopic,
  IUkmlaTopic,
} from '@quesmed/types-rn/models';
import { IStartOrJoinMarksheetData } from '@quesmed/types-rn/resolvers/mutation/restricted';

import {
  ButtonOnClickHandler,
  CheckboxState,
  InputOnChangeHandler,
  Nilable,
  StateCallback,
} from './general';

export enum QuestionAnswerStatus {
  CLEAN,
  FAMILIAR,
  INVALID,
  NOT_ANSWERED,
  SELECTED,
  VALID,
}

export enum Status {
  RIGHT,
  MAYBE,
  WRONG,
  ANSWERED,
}

export enum GameType {
  QUESTIONS,
  FLASHCARDS,
}

export enum QuestionButtonType {
  correct = 'correct',
  correctSelected = 'correct-selected',
  incorrect = 'incorrect',
  incorrectSelected = 'incorrect-selected',
  unknown = 'unknown',
  unknownSelected = 'unknown-selected',
  familiar = 'familiar',
  familiarSelected = 'familiar-selected',
  neutral = 'neutral',
}

export enum ItemStatusLabel {
  Answered = 'Answered',
  Correct = 'Correct',
  Completed = 'Completed',
  Incorrect = 'Incorrect',
  NotAnswered = 'Not answered',
  Confident = 'Confident',
  ToReview = 'To review',
  ToLearn = 'To learn',
  Performed = 'Performed',
  NotPerformed = 'Not performed',
  Urgent = 'Urgent',
  Unwatched = 'Unwatched',
  Watched = 'Watched',
  Unread = 'Unread',
  Revising = 'Revising',
  Complete = 'Complete',
}

export type Category = 'new' | 'popular' | 'personal' | 'live' | 'osce';

export type QuizTopicType = Exclude<
  ETopicType,
  ETopicType.ALL | ETopicType.OSCE | ETopicType.PSA | ETopicType.MOCK_TEST
>;

export type PreBuildData = Set<number> | undefined;

export interface ConceptMap {
  [key: number]: IConcept;
}
export interface ConditionMap {
  [key: number]: ICondition;
}
export interface PresentationMap {
  [key: number]: IPresentation;
}

export interface TopicItem {
  id: number;
  name: string;
  concepts?: ConceptMap;
}

export interface MLATopicItem {
  id: number;
  name: string;
  conditions?: ConditionMap;
  presentations?: PresentationMap;
}

export type ExtendedTopic = ITopic & { searchNames: string };
export type ExtendedMLATopic = IUkmlaTopic & { searchNames: string };

export interface TopicMap {
  [key: number]: TopicItem;
}

export interface MLATopicMap {
  [key: number]: MLATopicItem;
}

export interface WithQuestionCards {
  totalCards?: Nilable<number>;
  totalQuestions?: Nilable<number>;
}

export type TopicPredicate = (data: WithQuestionCards) => boolean;

export interface TopicSelectionState {
  id: number;
  topicState: CheckboxState;
  type: EProductType;
  selectedConceptsIds: Set<number>;
  conceptIds: Set<number>;
  conceptsAvailable: number;
}
export interface MLATopicSelectionState {
  id: number;
  topicState: CheckboxState;
  selectedPresentationsIds: Set<number>;
  presentationsIds: Set<number>;
  presentationsAvailable: number;
  selectedConditionsIds: Set<number>;
  conditionIds: Set<number>;
  conditionsAvailable: number;
}

export type SelectionState = Map<number, TopicSelectionState>;
export type MLASelectionState = Map<number, MLATopicSelectionState>;

export type SharedSelection = {
  [key: number]: { conceptsAvailable: number; conceptsSelected: number[] };
};

export interface TopicSelectionParams {
  topics: Nilable<ExtendedTopic[]>;
  marksheetId?: number;
  sharedSelection?: string;
  prevSelection?: string;
  onSetSelectionStateCallback?: (selectionState?: SelectionState) => void;
  displayTopics?: Nilable<ExtendedTopic[]>;
}
export interface MLATopicSelectionParams {
  topics: Nilable<ExtendedMLATopic[]>;
  marksheetId?: number;
  sharedSelection?: string;
  prevSelection?: string;
  onSetSelectionStateCallback?: (selectionState?: MLASelectionState) => void;
  displayTopics?: Nilable<ExtendedMLATopic[]>;
}

export interface TopicSelection {
  allSelected: CheckboxState;
  selectionState: SelectionState;
  handleSelectAll: (data: SelectionState) => InputOnChangeHandler;
  handleDeselectAll: (data: SelectionState) => ButtonOnClickHandler;
  handleSelectTopic: (
    data: SelectionState
  ) => (topicId: number) => InputOnChangeHandler;
  handleSelectConcept: (
    selectionState: SelectionState
  ) => (topicId: number) => (conceptId: number) => InputOnChangeHandler;
  selectAll: (data: SelectionState) => void;
  deselectAll: (data: SelectionState) => SelectionState;
  changeTopicState: (selectionState: SelectionState, topicId: number) => void;
  changeConceptState: (
    selectionState: SelectionState,
    topicId: number,
    conceptId: number
  ) => void;
  setSelectionState: StateCallback<SelectionState>;
}

export interface MLATopicSelection {
  allSelected: CheckboxState;
  selectionState: MLASelectionState;
  handleSelectAll: (data: MLASelectionState) => InputOnChangeHandler;
  handleDeselectAll: (data: MLASelectionState) => ButtonOnClickHandler;
  handleSelectTopic: (
    data: MLASelectionState
  ) => (topicId: number) => InputOnChangeHandler;
  handleSelectCondition: (
    selectionState: MLASelectionState
  ) => (topicId: number) => (conditionId: number) => InputOnChangeHandler;
  handleSelectPresentation: (
    selectionState: MLASelectionState
  ) => (topicId: number) => (presentationId: number) => InputOnChangeHandler;
  selectAll: (data: MLASelectionState) => void;
  deselectAll: (data: MLASelectionState) => MLASelectionState;
  changeTopicState: (
    selectionState: MLASelectionState,
    topicId: number
  ) => void;
  changeConditionState: (
    selectionState: MLASelectionState,
    topicId: number,
    conditionId: number
  ) => void;
  changePresentationState: (
    selectionState: MLASelectionState,
    topicId: number,
    presentationId: number
  ) => void;
  setSelectionState: StateCallback<MLASelectionState>;
}

export interface QuestionCount {
  unseen: number;
  seenIncorrect: number;
  seenCorrect: number;
}

export type BookMode = 'OsceBook' | 'QuesBook';

export enum Practice {
  NO_PRACTICE,
  SOME_PRACTICE,
  LOTS_OF_PRACTICE,
}

export interface Point {
  x: number;
  y: number;
}

export interface PerformanceData {
  generalData: Point[];
  generalMedian: number;
  myScore: number | null;
  myYearData: Point[];
  myYearMedian: number;
}

export type FlashcardsRouterParams = {
  cardId: string;
  todoId: string;
  markId: string;
  cardNumber: string;
};

export type QuestionsRouterParams = {
  marksheetId: string;
  markId: string;
  questionId: string;
  questionNumber: string;
};

export enum ExerciseProgressStatus {
  NotAnswered = 'not-answered',
  Correct = 'correct',
  Incorrect = 'incorrect',
  TestMode = 'test-mode',
  // Mock Test only
  Answered = 'answered',
  //Flashcard Status
  NotAtAll = 'not-at-all',
  Slightly = 'slightly',
  Moderately = 'moderately',
  KnowWell = 'know-well',
  Perfectly = 'perfectly',
  Default = 'default',
}

export enum SelectionBarLabels {
  UnselectAll = 'unselect all',
  RemoveSelection = 'remove selection',
  Unselect = 'unselect',
  SaveAsPreset = 'save as preset',
  Continue = 'continue',
  ReAttempt = 'reattempt',
  ReviewLastSession = 'review last session',
  Review = 'review',
  ReturnToSession = 'return to session',
  ReadInstructions = 'read instructions',
  ReadWalkthrough = 'read walkthrough',
  SaveChanges = 'save changes',
}

export enum QuestionAttempt {
  Unseen = 'Unseen',
  SeenCorrect = 'SeenCorrect',
  SeenIncorrect = 'SeenIncorrect',
}
export enum QuestionsMode {
  Quiz = 'Quiz',
  Test = 'Test',
}

export enum MockTestCategory {
  ALL = 'All',
  Miscellaneous = 'Miscellaneous',
  Finals = 'Finals',
  PSA = 'PSA',
  UniversitySpecific = 'University Specific',
  AnatomySpotter = 'Anatomy Spotter',
  UKMLA = 'UKMLA Mini Mock',
  UKMLA_FULL = 'UKMLA Full Mock',
  MRCP = 'Past Papers',
}

export interface QuestionFormInput {
  numberOfQuestions: number;
  questionsAttempt: QuestionAttempt[];
  difficulty: EDifficultyType[];
  mode: QuestionsMode;
  secondsPerQuestion?: number;
  unseen: number;
  seenIncorrect: number;
  seenCorrect: number;
}

export enum FlashcardScore {
  NotAtAll = 1,
  Slightly = 2,
  Moderately = 3,
  KnowWell = 4,
  Perfectly = 5,
}

export interface IAnswerObject {
  [key: string]: string[];
}

export type StartOrJoinMutation = (
  action: EStudyAction
) => Promise<FetchResult<IStartOrJoinMarksheetData>>;

export type MockTestRouter = {
  questionId?: string;
  marksheetId?: string;
  markId?: string;
  mockTestId?: string;
};

export type MatchingAnswer = [string, string][];
export type RankingAnswer = string[];
export type Select3Answer = [string, string, string];

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { joiResolver } from '@hookform/resolvers/joi';
import InputAdornment from '@mui/material/InputAdornment';
import Joi from 'joi';
import { useForm } from 'react-hook-form';
import {
  EDifficultyType,
  EEntitlementType,
  EMarksheetState,
} from '@quesmed/types-rn/models';
import Skeleton from '@mui/material/Skeleton';
import {
  IBuildConfigData,
  IBuildMarksheetData,
  IBuildMarksheetInput,
  IPreBuildMarksheet,
} from '@quesmed/types-rn/resolvers/mutation/restricted';
import { useNavigate } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import { Switch } from '@mui/material';

import { FormCheckboxGroup } from 'components/Checkbox';
import { Modal, ModalProps } from 'components/Modal/Modal';
import { FormField } from 'components/TextField';
import ModalSection from 'components/Modal/ModalSection';
import { FormSelect } from 'components/SelectField';
import {
  PRE_BUILD_DIFFICULTY_OPTIONS,
  QUESTION_DIFFICULTY_LEVELS,
} from 'config/constants';
import {
  PreBuildData,
  QuestionAttempt,
  QuestionFormInput,
  QuestionsMode,
  Undefinable,
} from 'types';
import {
  useBuildQuestions,
  useChangeBuilderConfig,
  useChangeMarksheetState,
} from './hooks';
import { SelectionLabel } from 'components/SelectionLabel';
import {
  calcMax,
  distributeQuestions,
  keys,
  parseBuilderConfig,
  round,
} from 'utils';
import { paths } from 'Router';
import { useSnackbar } from 'components/Snackbar';
import { Markdown } from 'components/Markdown';
import { Button } from 'components/Button';

export const SelectionCheckboxContainer = styled(Box)(
  ({ theme: { breakpoints, spacing } }) => ({
    '& .MuiFormGroup-root': {
      position: 'relative',
      paddingBottom: spacing(5),

      '&:not(:last-child)': {
        marginBottom: spacing(2),
      },
    },

    [breakpoints.up('md')]: {
      '& .MuiFormGroup-root': {
        paddingBottom: 0,
      },
    },
  })
);

const MoreInfoButton = styled(Button)(({ theme: { spacing } }) => ({
  marginTop: spacing(2),
}));

const AI_BUILDER_INFO = `
Questions are typically randomised to replicate your exams and for many, this approach to revision is ideal. However, at different stages of your revision, you may prefer a more targeted approach.

As you answer questions and progress through your revision, your Quesmed Qbank learns about what you know well and what you don't know as well, and our AI uses algorithms to tailor and personalise your revision. This means you will be preferentially shown questions to target weaker areas of knowledge. The parameters taken into consideration by our algorithms include:

Dynamic difficulty - question difficulty is influenced by your average score, showing questions similar to what you are currently scoring
Previous concept performance - concepts you have scored worse on are more likely to appear
Key concept importance - questions related to concepts you have marked as urgent or revising are preferentially shown
Question relevance - incorrect questions whose learning points have previously been marked as 'not relevant' are not shown

Your statistics are checked daily and the algorithms are adjusted accordingly, so as your revision priorities change, so are the kinds of questions you will be shown!
`;

const MINIMUM_QUESTIONS_NUMBER = 1;
const DEFAULT_QUESTION_NUMBER = 20;
const MAXIMUM_QUESTIONS_NUMBER = 200;
const MINIMUM_QUESTION_TIME = 50; // seconds
const DEFAULT_QUESTION_TIME = 70; // seconds
const MAXIMUM_QUESTION_TIME = 600; // seconds

const MODE_OPTIONS = [
  { label: 'Quiz mode', value: QuestionsMode.Quiz },
  {
    label: 'Test mode',
    value: QuestionsMode.Test,
  },
];

const DEFAULT_FORM_FALUES = {
  numberOfQuestions: DEFAULT_QUESTION_NUMBER,
  questionsAttempt: [
    QuestionAttempt.SeenCorrect,
    QuestionAttempt.SeenIncorrect,
    QuestionAttempt.Unseen,
  ],
  unseen: 0,
  seenIncorrect: 0,
  seenCorrect: 0,
  difficulty: QUESTION_DIFFICULTY_LEVELS,
  mode: QuestionsMode.Quiz,
  secondsPerQuestion: DEFAULT_QUESTION_TIME,
};

const TOTAL_QUESTIONS_DISTRIBUTION = {
  totalUnseen: 0,
  totalSeenIncorrect: 0,
  totalSeenCorrect: 0,
};

const isFormDataDifferent = (
  newFormData: QuestionFormInput,
  oldFormData: QuestionFormInput
) =>
  keys(oldFormData).some(key => !isEqual(oldFormData[key], newFormData[key]));

const isBuilderDifferent = (
  newBuilder: IBuildConfigData,
  oldBuilder?: IBuildConfigData
) =>
  !oldBuilder ||
  keys(oldBuilder).some(key => !isEqual(oldBuilder[key], newBuilder[key]));

const INITIBUILDER_CONFIG: IBuildConfigData = {
  numberOfQuestions: 0,
  secondsPerQuestion: 70,
  isTest: false,
  difficulty: QUESTION_DIFFICULTY_LEVELS,
};

const getSchema = (
  max = MAXIMUM_QUESTIONS_NUMBER,
  maxUnseen = MAXIMUM_QUESTIONS_NUMBER,
  maxSeenIncorrect = MAXIMUM_QUESTIONS_NUMBER,
  maxSeenCorrect = MAXIMUM_QUESTIONS_NUMBER
) =>
  Joi.object<QuestionFormInput>({
    numberOfQuestions: Joi.number()
      .integer()
      .min(MINIMUM_QUESTIONS_NUMBER)
      .max(max)
      .required()
      .messages({
        'any.required': 'Enter a number of questions',
        'number.base': 'Amount of questions must be a number',
        'number.min': `Select at least ${MINIMUM_QUESTIONS_NUMBER} question`,
        'number.max': `Select maximum ${max} questions`,
        'number.integer': 'Enter an integer',
      }),
    unseen: Joi.number()
      .integer()
      .min(0)
      .max(maxUnseen)
      .required()
      .messages({
        'any.required': 'Enter a number of questions',
        'number.base': 'Amount of questions must be a number',
        'number.min': `Select at least ${MINIMUM_QUESTIONS_NUMBER} question`,
        'number.max': `Select maximum ${maxUnseen} questions`,
        'number.integer': 'Enter an integer',
      }),
    seenIncorrect: Joi.number()
      .integer()
      .min(0)
      .max(maxSeenIncorrect)
      .required()
      .messages({
        'any.required': 'Enter a number of questions',
        'number.base': 'Amount of questions must be a number',
        'number.min': `Select at least ${MINIMUM_QUESTIONS_NUMBER} question`,
        'number.max': `Select maximum ${maxSeenIncorrect} questions`,
        'number.integer': 'Enter an integer',
      }),
    seenCorrect: Joi.number()
      .integer()
      .min(0)
      .max(maxSeenCorrect)
      .required()
      .messages({
        'any.required': 'Enter a number of questions',
        'number.base': 'Amount of questions must be a number',
        'number.min': `Select at least ${MINIMUM_QUESTIONS_NUMBER} question`,
        'number.max': `Select maximum ${maxSeenCorrect} questions`,
        'number.integer': 'Enter an integer',
      }),
    questionsAttempt: Joi.array().items(Joi.string()).min(1).messages({
      'array.min': 'Select at least one question type',
    }),
    difficulty: Joi.array().items(Joi.number()).min(1).messages({
      'array.min': 'Select at least one difficulty level',
    }),
    mode: Joi.string()
      .valid(QuestionsMode.Quiz, QuestionsMode.Test)
      .default(QuestionsMode.Quiz),
    secondsPerQuestion: Joi.alternatives().conditional('mode', {
      is: QuestionsMode.Test,
      then: Joi.number()
        .integer()
        .min(MINIMUM_QUESTION_TIME)
        .max(MAXIMUM_QUESTION_TIME)
        .required()
        .messages({
          'any.required': 'Enter question time',
          'number.base': 'Question time must be number',
          'number.min': `Enter minimum ${MINIMUM_QUESTION_TIME} seconds`,
          'number.max': `Enter maximum ${MAXIMUM_QUESTION_TIME} seconds (10 minute)`,
          'number.integer': 'Enter an integer',
        }),
      otherwise: Joi.number(),
    }),
  });

const parseQuestionsAttempted = (
  questionsAttempt?: (QuestionAttempt | undefined)[]
) => {
  const unseen = Boolean(questionsAttempt?.includes(QuestionAttempt.Unseen));
  const seenIncorrect = Boolean(
    questionsAttempt?.includes(QuestionAttempt.SeenIncorrect)
  );
  const seenCorrect = Boolean(
    questionsAttempt?.includes(QuestionAttempt.SeenCorrect)
  );

  return { unseen, seenIncorrect, seenCorrect };
};

const calcMaxAndDistributeQuestions = (
  totalUnseen: number,
  totalCorrect: number,
  totalIncorrect: number,
  requestedNumber: number,
  questionsAttempt?: (QuestionAttempt | undefined)[]
) => {
  const { unseen, seenIncorrect, seenCorrect } =
    parseQuestionsAttempted(questionsAttempt);

  const max = calcMax(
    totalUnseen,
    totalIncorrect,
    totalCorrect,
    unseen,
    seenIncorrect,
    seenCorrect
  );

  const distribution = distributeQuestions(
    round(Number(requestedNumber)),
    totalUnseen,
    totalIncorrect,
    totalCorrect,
    unseen,
    seenIncorrect,
    seenCorrect
  );

  return { max, ...distribution };
};

interface QuestionsPreBuildModalProps
  extends Pick<ModalProps, 'open' | 'onClose' | 'onBack'> {
  preBuildData?: IPreBuildMarksheet;
  builderConfig?: IBuildConfigData;
  search?: string;
  selection?: PreBuildData;
  solo?: boolean;
  source?: string;
  marksheetId?: number;
  onStartQuiz?: () => void;
  entitlementId?: EEntitlementType;
}

const QuestionsPreBuildModal = ({
  onClose,
  onStartQuiz,
  onBack,
  preBuildData: groupPreBuildData,
  builderConfig,
  marksheetId,
  entitlementId,
  selection,
  open,
  search = '',
  solo = true,
  source = '',
}: QuestionsPreBuildModalProps): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const { changeBuilderConfig } = useChangeBuilderConfig(marksheetId);
  const { changeMarksheetState } = useChangeMarksheetState(marksheetId);
  const navigate = useNavigate();
  const [isInitialized, setIsInitialized] = useState(false);
  const [currentMaxValue, setCurrentMaxValue] = useState<number>(0);
  const [currentMaxUnseenValue, setCurrentMaxUnseenValue] = useState<number>(0);
  const [currentMaxSeenCorrectValue, setCurrentMaxSeenCorrectValue] =
    useState<number>(0);
  const [currentMaxSeenIncorrectValue, setCurrentMaxSeenIncorrectValue] =
    useState<number>(0);
  const [totalDistribution, setTotalDistribution] = useState(
    TOTAL_QUESTIONS_DISTRIBUTION
  );
  const [aiBuilder, setAiBuilder] = useState(false);
  const [aiInfoModalOpen, setAiInfoModalOpen] = useState(false);
  const schema = useMemo(
    () =>
      getSchema(
        currentMaxValue,
        currentMaxUnseenValue,
        currentMaxSeenIncorrectValue,
        currentMaxSeenCorrectValue
      ),
    [
      currentMaxValue,
      currentMaxUnseenValue,
      currentMaxSeenCorrectValue,
      currentMaxSeenIncorrectValue,
    ]
  );

  const {
    control,
    formState,
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset,
    trigger,
  } = useForm<QuestionFormInput>({
    defaultValues: DEFAULT_FORM_FALUES,
    values:
      builderConfig && solo ? parseBuilderConfig(builderConfig) : undefined,
    resolver: joiResolver(schema),
    mode: 'onTouched',
  });

  const { isValid } = formState;
  const watchForm = watch();
  const { mode } = watchForm;
  const formDataRef = useRef<QuestionFormInput>(watchForm);
  const timeInputRef = useRef<HTMLInputElement>(null);
  const builderConfigRef = useRef<Undefinable<IBuildConfigData>>(builderConfig);

  useEffect(() => {
    let timeout = 0;
    if (
      !solo &&
      isValid &&
      isFormDataDifferent(formDataRef.current, watchForm)
    ) {
      timeout = window.setTimeout(() => {
        const {
          questionsAttempt,
          numberOfQuestions,
          secondsPerQuestion,
          mode,
        } = watchForm;

        formDataRef.current = watchForm;

        changeBuilderConfig({
          ...parseQuestionsAttempted(questionsAttempt),
          numberOfQuestions: Number(numberOfQuestions),
          secondsPerQuestion: Number(secondsPerQuestion),
          isTest: mode === QuestionsMode.Test,
        });
      }, 700);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [changeBuilderConfig, isValid, solo, watchForm]);

  useEffect(() => {
    if (mode === QuestionsMode.Test && timeInputRef.current) {
      timeInputRef.current.focus();
    }
  }, [mode]);

  const startQuestions = (data?: IBuildMarksheetData) => {
    if (!solo) {
      return;
    }
    const { id } = data?.restricted?.buildMarksheet || {};

    if (id) {
      navigate(`${paths.questions.root}/solo/quiz/${id}`, {
        state: { leave: true },
      });

      if (onStartQuiz) {
        onStartQuiz();
      }
    } else {
      enqueueSnackbar('Unable to start expired session. Please try again.');
    }
  };

  const {
    preBuildData: soloPreBuildData,
    preBuildLoading,
    buildLoading,
    buildQuestions,
    preBuildQuestions,
  } = useBuildQuestions({
    source,
    search,
    marksheetId,
    onBuildComplete: startQuestions,
  });

  const preBuildData = useMemo(
    () => (solo || !groupPreBuildData ? soloPreBuildData : groupPreBuildData),
    [groupPreBuildData, solo, soloPreBuildData]
  );

  const { buildRef = 0 } = preBuildData || {};
  const { totalUnseen, totalSeenCorrect, totalSeenIncorrect } =
    totalDistribution;

  const globalLoading = preBuildLoading || buildLoading;
  const initialLoading = preBuildLoading && !isInitialized;

  useEffect(() => {
    if (preBuildLoading) {
      return;
    }

    const {
      unseen = 0,
      seenCorrect = 0,
      seenIncorrect = 0,
    } = preBuildData || {};

    setTotalDistribution({
      totalUnseen: unseen,
      totalSeenCorrect: seenCorrect,
      totalSeenIncorrect: seenIncorrect,
    });
    const newQuestionsAttmepted = [];

    if (unseen) {
      newQuestionsAttmepted.push(QuestionAttempt.Unseen);
    }

    if (seenCorrect) {
      newQuestionsAttmepted.push(QuestionAttempt.SeenCorrect);
    }

    if (seenIncorrect) {
      newQuestionsAttmepted.push(QuestionAttempt.SeenIncorrect);
    }

    setValue('questionsAttempt', newQuestionsAttmepted);

    const targetTotal = Math.min(
      DEFAULT_QUESTION_NUMBER,
      unseen + seenIncorrect + seenCorrect
    );

    const unseenCount = Math.min(unseen, targetTotal);
    setValue('unseen', unseenCount);

    const remainingAfterUnseen = targetTotal - unseenCount;
    const seenIncorrectCount = Math.min(seenIncorrect, remainingAfterUnseen);
    setValue('seenIncorrect', seenIncorrectCount);

    const remainingAfterIncorrect = remainingAfterUnseen - seenIncorrectCount;
    const seenCorrectCount = Math.min(seenCorrect, remainingAfterIncorrect);
    setValue('seenCorrect', seenCorrectCount);

    setValue(
      'numberOfQuestions',
      unseenCount + seenIncorrectCount + seenCorrectCount
    );
  }, [preBuildLoading, preBuildData, setValue]);

  useEffect(() => {
    if (
      builderConfig &&
      isBuilderDifferent(builderConfig, builderConfigRef.current)
    ) {
      reset(parseBuilderConfig(builderConfig), { keepErrors: true });
    }
  }, [builderConfig, reset]);

  useEffect(() => {
    preBuildQuestions(
      selection,
      getValues('difficulty'),
      entitlementId,
      aiBuilder
    );
  }, [aiBuilder, entitlementId, getValues, preBuildQuestions, selection]);

  useEffect(() => {
    if (globalLoading) {
      return;
    }

    let isUpdatingFromTotal = false;

    const { max } = calcMaxAndDistributeQuestions(
      getValues('unseen'),
      getValues('seenCorrect'),
      getValues('seenIncorrect'),
      getValues('numberOfQuestions'),
      getValues('questionsAttempt')
    );

    setCurrentMaxUnseenValue(totalUnseen);
    setCurrentMaxSeenCorrectValue(totalSeenCorrect);
    setCurrentMaxSeenIncorrectValue(totalSeenIncorrect);
    setCurrentMaxValue(max);

    const subscription = watch((data, { name, type }) => {
      const { questionsAttempt = [], numberOfQuestions = 0, difficulty } = data;

      if (name === 'numberOfQuestions' && !isUpdatingFromTotal) {
        const targetTotal = Number(numberOfQuestions);

        const unseenCount = Math.min(totalUnseen, targetTotal);
        setValue('unseen', unseenCount);

        const remainingAfterUnseen = targetTotal - unseenCount;
        const seenIncorrectCount = Math.min(
          totalSeenIncorrect,
          remainingAfterUnseen
        );
        setValue('seenIncorrect', seenIncorrectCount);

        const remainingAfterIncorrect =
          remainingAfterUnseen - seenIncorrectCount;
        const seenCorrectCount = Math.min(
          totalSeenCorrect,
          remainingAfterIncorrect
        );
        setValue('seenCorrect', seenCorrectCount);

        trigger();
      }

      if (
        (name === 'unseen' ||
          name === 'seenIncorrect' ||
          name === 'seenCorrect') &&
        !isUpdatingFromTotal
      ) {
        isUpdatingFromTotal = true;
        const newTotal =
          Number(data.unseen || 0) +
          Number(data.seenIncorrect || 0) +
          Number(data.seenCorrect || 0);

        setValue('numberOfQuestions', newTotal);
        trigger('numberOfQuestions');
        isUpdatingFromTotal = false;
      }

      if (
        name === 'difficulty' &&
        type === 'change' &&
        difficulty &&
        selection
      ) {
        preBuildQuestions(
          selection,
          difficulty as EDifficultyType[],
          entitlementId,
          aiBuilder
        );
      }

      const { max } = calcMaxAndDistributeQuestions(
        totalUnseen,
        totalSeenCorrect,
        totalSeenIncorrect,
        numberOfQuestions,
        questionsAttempt
      );

      setCurrentMaxValue(max);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [
    builderConfig,
    getValues,
    globalLoading,
    isValid,
    preBuildQuestions,
    selection,
    entitlementId,
    solo,
    totalSeenCorrect,
    totalSeenIncorrect,
    totalUnseen,
    trigger,
    watch,
    aiBuilder,
    setValue,
  ]);

  useEffect(() => {
    if (!isInitialized) {
      if (currentMaxValue) {
        setValue(
          'numberOfQuestions',
          Math.min(currentMaxValue, DEFAULT_QUESTION_NUMBER)
        );
        setIsInitialized(true);
        trigger();
      }
    } else {
      setValue(
        'numberOfQuestions',
        Math.min(Number(getValues('numberOfQuestions')), currentMaxValue)
      );
    }
  }, [getValues, isInitialized, currentMaxValue, setValue, trigger]);

  useEffect(() => {
    if (
      open &&
      !isInitialized &&
      (selection || (search && source)) &&
      !preBuildLoading &&
      !preBuildData &&
      solo
    ) {
      preBuildQuestions(
        selection,
        QUESTION_DIFFICULTY_LEVELS,
        entitlementId,
        aiBuilder
      );
    }
  }, [
    entitlementId,
    solo,
    preBuildData,
    isInitialized,
    open,
    preBuildQuestions,
    selection,
    preBuildLoading,
    search,
    source,
    aiBuilder,
  ]);

  const backToQuestionBuilder = useCallback(async () => {
    await changeMarksheetState(EMarksheetState.PRESTART);
    await changeBuilderConfig(INITIBUILDER_CONFIG);
  }, [changeBuilderConfig, changeMarksheetState]);

  const handleClose = useCallback(() => {
    onClose?.();
    reset();
    if (!solo) {
      backToQuestionBuilder();
    }
  }, [backToQuestionBuilder, onClose, reset, solo]);

  const handleBuild = useMemo(
    () =>
      handleSubmit(data => {
        if (data) {
          const { mode, secondsPerQuestion } = data;
          const params: IBuildMarksheetInput = {
            buildRef,
            isTest: mode === QuestionsMode.Test,
            secondsPerQuestion: Number(secondsPerQuestion),
            unseen: data.unseen,
            seenIncorrect: data.seenIncorrect,
            seenCorrect: data.seenCorrect,
          };

          buildQuestions(params);
        }
      }),
    [buildQuestions, buildRef, handleSubmit]
  );

  const handleChangeAIBuilder = () => {
    setAiBuilder(prev => !prev);
  };

  const handleOpenAIInfoModal = () => {
    setAiInfoModalOpen(true);
  };

  const handleCloseAIInfoModal = () => {
    setAiInfoModalOpen(false);
  };

  const QUESTION_ATTEMPT_OPTIONS = useMemo(
    () => [
      {
        label: (
          <SelectionLabel
            label="Not answered questions"
            selectedCount={
              <FormField
                InputProps={{
                  inputProps: {
                    min: 0,
                    max: totalUnseen,
                  },
                }}
                control={control}
                disabled={totalUnseen === 0}
                name="unseen"
                type="number"
              />
            }
            totalCount={totalUnseen}
          />
        ),
        value: QuestionAttempt.Unseen,
        id: QuestionAttempt.Unseen,
        disabled: totalUnseen === 0,
      },
      {
        label: (
          <SelectionLabel
            label="Previously incorrectly answered"
            selectedCount={
              <FormField
                InputProps={{
                  inputProps: {
                    min: 0,
                    max: totalSeenIncorrect,
                  },
                }}
                control={control}
                disabled={totalSeenIncorrect === 0}
                name="seenIncorrect"
                type="number"
              />
            }
            totalCount={totalSeenIncorrect}
          />
        ),
        value: QuestionAttempt.SeenIncorrect,
        id: QuestionAttempt.SeenIncorrect,
        disabled: totalSeenIncorrect === 0,
      },
      {
        label: (
          <SelectionLabel
            label="Previously correctly answered"
            selectedCount={
              <FormField
                InputProps={{
                  inputProps: {
                    min: 0,
                    max: totalSeenIncorrect,
                  },
                }}
                control={control}
                disabled={totalSeenCorrect === 0}
                name="seenCorrect"
                type="number"
              />
            }
            totalCount={totalSeenCorrect}
          />
        ),
        value: QuestionAttempt.SeenCorrect,
        id: QuestionAttempt.SeenCorrect,
        disabled: totalSeenCorrect === 0,
      },
    ],
    [control, totalSeenCorrect, totalSeenIncorrect, totalUnseen]
  );

  const submitDisabled = (!isValid || globalLoading) && !search && !source;

  return (
    <Modal
      loading={buildLoading}
      maxWidth="md"
      noPaddingY
      onBack={onBack}
      onClose={handleClose}
      onSubmit={handleBuild}
      open={open}
      showCloseButton
      sizeVariant="md"
      submitDisabled={submitDisabled}
      submitLabel="start exercise"
      title="Set up the exercise"
    >
      <Box>
        <ModalSection sx={{ marginTop: 0 }} title="Number of Questions">
          {initialLoading ? (
            <Skeleton height={80} variant="rectangular" />
          ) : (
            <FormField
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    maximum: {currentMaxValue}
                  </InputAdornment>
                ),
                readOnly: globalLoading,
              }}
              control={control}
              fullWidth
              helperText="Total number of questions to be included in the exercise."
              label="Questions"
              name="numberOfQuestions"
              type="number"
            />
          )}
        </ModalSection>
        {solo ? (
          <ModalSection
            subTitle={
              <MoreInfoButton onClick={handleOpenAIInfoModal} secondary>
                More information
              </MoreInfoButton>
            }
            sx={{ marginBottom: 0 }}
            title="AI Question Builder"
            variant="row"
          >
            {initialLoading ? (
              <Skeleton height={48} variant="rectangular" />
            ) : (
              <Switch checked={aiBuilder} onChange={handleChangeAIBuilder} />
            )}
          </ModalSection>
        ) : null}
        <ModalSection
          subTitle="You can choose more than one option."
          sx={{ marginBottom: 0 }}
          title="Questions type"
        >
          {initialLoading ? (
            <Skeleton height={100} variant="rectangular" />
          ) : (
            <SelectionCheckboxContainer>
              <FormCheckboxGroup
                control={control}
                controlSx={{
                  '& .MuiTypography-root': { width: '100%' },
                }}
                name="questionsAttempt"
                options={QUESTION_ATTEMPT_OPTIONS}
                readOnly={globalLoading}
              />
            </SelectionCheckboxContainer>
          )}
        </ModalSection>
        <ModalSection
          subTitle="You can choose more than one option."
          sx={{ marginBottom: 0 }}
          title="Difficulty level"
        >
          {initialLoading ? (
            <Skeleton height={100} variant="rectangular" />
          ) : (
            <FormCheckboxGroup
              control={control}
              name="difficulty"
              numericValue
              options={PRE_BUILD_DIFFICULTY_OPTIONS}
              readOnly={globalLoading}
              withInfo
            />
          )}
        </ModalSection>
        <ModalSection
          className="mode-type"
          subTitle="Test mode starts a timer and shows your answers at the end."
          sx={{ marginBottom: 0 }}
          title="Mode type"
          variant="row"
        >
          {initialLoading ? (
            <Skeleton height={48} variant="rectangular" />
          ) : (
            <FormSelect
              InputProps={{
                readOnly: globalLoading,
              }}
              control={control}
              dropdownPosition="top"
              name="mode"
              options={MODE_OPTIONS}
              select
              size="small"
            />
          )}
        </ModalSection>
        {mode === QuestionsMode.Test && solo ? (
          <FormField
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">seconds</InputAdornment>
              ),
              readOnly: globalLoading,
            }}
            control={control}
            fullWidth
            helperText="Time taken to answer one question."
            inputRef={timeInputRef}
            label="Question time"
            name="secondsPerQuestion"
            placeholder="ss"
            sx={{ marginTop: 6 }}
            type="number"
          />
        ) : null}
        {aiInfoModalOpen ? (
          <Modal
            noPaddingY
            onClose={handleCloseAIInfoModal}
            onSubmit={handleCloseAIInfoModal}
            open={aiInfoModalOpen}
            submitLabel="Got It"
            title="AI Question Builder"
          >
            <Markdown text={AI_BUILDER_INFO} />
          </Modal>
        ) : null}
      </Box>
    </Modal>
  );
};

export default QuestionsPreBuildModal;

import React, { ChangeEvent, ReactNode } from 'react';
import {
  Controller,
  ControllerProps,
  FieldValues,
  Path,
  PathValue,
} from 'react-hook-form';
import FormHelperText from '@mui/material/FormHelperText';
import Box, { BoxProps } from '@mui/material/Box';

import { StyledCheckboxWrapper } from './Checkbox.styles';
import { Checkbox, CheckboxProps } from './Checkbox';
import { compare, convertEventTargetValueToNumber } from 'utils';

type CheckboxGroupOnChangeHandler = (value: (string | number)[]) => void;

function handleChange<T extends FieldValues>(
  onChange: CheckboxGroupOnChangeHandler,
  allValues: PathValue<T, Path<T>>,
  numericValue?: boolean
) {
  function checkboxGroupEventHandle(
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) {
    const { target } = convertEventTargetValueToNumber(event, numericValue);

    if (checked) {
      onChange([target.value, ...(allValues || [])]);
    } else {
      onChange(allValues.filter((v: unknown) => !compare(v, target.value)));
    }
  }

  return checkboxGroupEventHandle;
}

export interface CheckboxOption {
  id: string | number;
  label: ReactNode;
  value: string | number | boolean;
  infoTip?: string;
  disabled?: boolean;
}

export type FormCheckboxGroupProps<T extends FieldValues> = Omit<
  ControllerProps<T>,
  'render'
> &
  Omit<CheckboxProps, 'label'> & {
    component?: typeof Checkbox;
    options: CheckboxOption[];
    groupSx?: BoxProps['sx'];
    containerSx?: BoxProps['sx'];
    numericValue?: boolean;
    withInfo?: boolean;
  };

export function FormCheckboxGroup<T extends FieldValues>({
  component: Component = Checkbox,
  containerSx,
  control,
  defaultValue,
  groupSx,
  rules,
  name,
  options,
  shouldUnregister,
  controlSx,
  numericValue,
  withInfo,
}: FormCheckboxGroupProps<T>): JSX.Element {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({
        field: { onBlur, onChange, value: allValues },
        fieldState: { error },
      }) => (
        <StyledCheckboxWrapper
          className={error ? 'error' : ''}
          sx={containerSx}
        >
          <Box sx={groupSx}>
            {options.map(({ id, label, value, infoTip, disabled }) => (
              <Component
                checked={allValues.some((v: unknown) => compare(value, v))}
                color={error ? 'error' : undefined}
                controlSx={controlSx}
                disabled={disabled}
                infoTip={infoTip}
                key={id}
                label={label}
                name={name}
                onBlur={onBlur}
                onChange={handleChange(onChange, allValues, numericValue)}
                value={value}
                withInfo={withInfo}
              />
            ))}
          </Box>
          {error ? (
            <FormHelperText error={Boolean(error)}>
              {error?.message}
            </FormHelperText>
          ) : null}
        </StyledCheckboxWrapper>
      )}
      rules={rules}
      shouldUnregister={shouldUnregister}
    />
  );
}
